<template>
  <AuthWrapper  >
     <div class="auth-contents">
       <a-form @submit.prevent="handleSubmit" :model="formState" layout="vertical">
        <center>
        <!-- <img class="auth-content-figure" :src="require('@/static/img/them_logo.jpg')" alt="" /> -->
        <sdHeading as="h3"> Sign in 
          <!-- to <span class="color-secondary">Admin</span> -->
        </sdHeading>
        </center>
        <a-alert
          v-if="message != null"
          :outlined="false"
          :closable="false"
          :showIcon="false"
          :description="message"
          type="error"
        />
        <!-- <br /> -->
        <a-form-item name="username" label="Email Address">
          <a-input
            type="email"
            v-model:value="formState.email"
            @input="emailField.handleChange"
            @blur="emailField.handleBlur"
            :value="emailField.value"
          />
          <a-alert
            v-if="emailField.meta.touched && !emailField.meta.valid"
            :outlined="false"
            :closable="false"
            :showIcon="false"
            message=""
            :description="emailField.errorMessage || 'Username is Required'"
            type="error"
          />
        </a-form-item>

        <a-form-item name="password" initialValue="123456" label="Password">
          <a-input
            type="password"
            v-model:value="formState.password"
            placeholder="Password"
            @input="passwordField.handleChange($event)"
            @blur="passwordField.handleBlur"
            :value="passwordField.value"
          />
          <a-alert
            v-if="passwordField.meta.touched && !passwordField.meta.valid"
            :outlined="false"
            :closable="false"
            :showIcon="false"
            message=""
            :description="passwordField.errorMessage || 'Password is Required'"
            type="error"
          />
        </a-form-item>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">Keep me logged in</a-checkbox>
          <router-link class="forgot-pass-link" to="/auth/forgotPassword"> Forgot password? </router-link>
        </div>
        <a-form-item :disabled="!formMeta.valid">
          <a-button class="btn-signin" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </a-button>
        </a-form-item>
        <p class="form-divider">
          <span>Or</span>
        </p>
        <p>Don&rsquo;t have an account? <router-link to="/auth/register">Sign up now</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script> 
import { computed, reactive, ref, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router'; 
import { useField, useForm } from 'vee-validate';
const { meta: formMeta,   } = useForm();
  
const SignIn = defineComponent({
  name: 'SignIn',
  components: {   AuthWrapper, },
    setup() {
    const { state, dispatch } = useStore();
    var isLoading = computed(() => state.auth.loading);
    const checked = ref(null);
    const router = useRouter();
    var message = ref(null);
    const emailField = reactive(useField('email', 'email'));
    const passwordField = reactive(useField('password', 'password'));

    const  handleSubmit = () => {
      // router.push('/');
      // dispatch('login');
      // e.preventDefault();
      isLoading = true;
      if (passwordField.meta.valid && emailField.meta.valid) {
        dispatch('login', formState).then(
          ( ) => {
            
            isLoading = false;
            state.auth.login = true;
            router.push('/');
          },
          (error) => {
            if (typeof error != 'string') {
              isLoading = false;
              message.value = 'Wrong email id or password';
            } else {
              isLoading = false;
              message.value = 'Invalid Credentials';
            }

            
          },
        );
      }
    };
    const onChange = (checked) => {
      checked.value = checked;
    };

    const formState = reactive({
      email: '',
      password: '',
    });

    return {
      isLoading,
      checked,
      handleSubmit,
      onChange,
      formState, 
      emailField,
      passwordField,
      formMeta,
      message,
    };
  },
});

export default SignIn;
</script>
